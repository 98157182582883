<script setup lang="ts">
const { data, signOut, sendPasswordResetEmail } = useAuthAdapter()
const router = useRouter()

function redirect_to_home() {
  router.push('/')
}

async function changePassword() {
  try {
    if (!data.value?.user?.email) {
      throw new Error('User is not authenticated or email is not available')
    }

    await sendPasswordResetEmail()
    alert('Password reset email sent successfully')
  }
  catch (error) {
    alert('Failed to send password reset email')
  }
}

function sendSupportEmail(event: MouseEvent | KeyboardEvent) {
  event.preventDefault() // Prevent any default action

  window.location = 'mailto:support@deepbluelinks.com?subject=Support Request'
}
</script>

<template>
  <nav>
    <v-app-bar
      color="rgb(38,40,80)"
      :elevation="12"
      scroll-behavior="hide"
    >
      <template #prepend>
        <button @click="redirect_to_home">
          <img
            src="../../assets/images/Logo.svg"
            style="height: 28px;"
          >
        </button>
      </template>

      <v-spacer />
      <v-btn
        id="documentation"
        class="mr-4 border-0 text-capitalize"
        href="https://deep-blue-links.readme.io/"
        target="_blank"
      >
        Documentation
      </v-btn>

      <v-menu>
        <template #activator="{ props }">
          <v-btn
            icon
            v-bind="props"
          >
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="signOut()">
            <v-list-item-title>Sign Out</v-list-item-title>
          </v-list-item>
          <v-list-item @click="sendSupportEmail($event)">
            <v-list-item-title>Support</v-list-item-title>
          </v-list-item>
          <v-list-item @click="changePassword()">
            <v-list-item-title>Change Password</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </nav>
</template>

<style scoped>
#documentation{
  background-color: transparent !important;
}
</style>
