<script setup lang="ts">

</script>

<template>
  <footer />
</template>

  <style scoped>

  </style>
